import { NotFoundError, useCrash } from '@orus.eu/pharaoh'
import { isFailure } from '@orus.eu/result'
import { useSearch } from '@tanstack/react-router'
import { memo, useEffect, useMemo, useRef } from 'react'
import { getSubscriptionInjectableParamsFromURLSearchParams } from '../../../../../lib/subscription-injectable-params'
import { GlobalLoadingState } from '../../../../molecules/global-loading-state'
import { useCreateNewSubscription } from './use-create-new-subscription'

const PlatformNewSubscriptionPage = memo(function PlatformNewSubscriptionPage() {
  const createNewSubscription = useCreateNewSubscription()
  const crash = useCrash()
  const searchParams = useSearch({ strict: false })
  const isCreatingSubscription = useRef(false)

  const newSubscriptionParamsResult = useMemo(
    () => getSubscriptionInjectableParamsFromURLSearchParams(searchParams),
    [searchParams],
  )

  useEffect(() => {
    if (isCreatingSubscription.current) return

    if (isFailure(newSubscriptionParamsResult)) {
      switch (newSubscriptionParamsResult.problem.type) {
        case 'invalid-params':
          crash(new NotFoundError())
          return
      }
    }

    isCreatingSubscription.current = true
    createNewSubscription(newSubscriptionParamsResult.output)
  }, [crash, createNewSubscription, newSubscriptionParamsResult])

  return <GlobalLoadingState />
})

export default PlatformNewSubscriptionPage
