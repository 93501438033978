import type { Amount } from '@orus.eu/amount'
import type { CompanyIdNumber } from '@orus.eu/company-id-number'
import { useAsyncCallback } from '@orus.eu/pharaoh'
import type { JsonableSiretData } from '@orus.eu/siret'
import { useNavigate } from '@tanstack/react-router'
import { useState } from 'react'
import { v4 } from 'uuid'
import { trpcReact } from '../../../../../client'

export type NewSubscriptionParams = {
  activity?: string
  firstName?: string
  lastName?: string
  companyIdNumber?: CompanyIdNumber
  siretData?: JsonableSiretData
  email?: string
  estimatedRevenue?: Amount
  phone?: string
}

export function useCreateNewSubscription(): (newSubscriptionParams?: NewSubscriptionParams) => void {
  const navigate = useNavigate()
  const [createNewSubscriptionIdempotencyKey] = useState(v4())
  const createSubscriptionsMutation = trpcReact.subscriptions.createSubscriptionWithInitialValues.useMutation()

  return useAsyncCallback(
    async (newSubscriptionParams?: NewSubscriptionParams) => {
      const subscriptionId = await createSubscriptionsMutation.mutateAsync({
        ...newSubscriptionParams,
        idempotencyKey: createNewSubscriptionIdempotencyKey,
      })

      const route = {
        to: '/bak/v2-pending-subscriptions/$subscriptionId',
        params: { subscriptionId },
      }

      void navigate(route)
    },
    [navigate, createSubscriptionsMutation, createNewSubscriptionIdempotencyKey],
  )
}
